import './App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './component/Header';
import Footer from './component/Footer';
import Dashboard from './component/Dashboard';
import Introduction from './component/Introduction';
import Body from './component/Body';
import { Outlet } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import LoadingIndicator from './component/LoadingIndicator';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(true);
  const [showIntroduction, setShowIntroduction] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      setIsLoggedIn(true);
    } else {
      navigate('/');
    }
  }, [navigate]);

  useEffect(() => {
    if (isLoggedIn && location.pathname === '/') {
      navigate('/introduction');
    }
    if (!isLoggedIn && location.pathname !== '/') {
      navigate('/');
    }
  }, [isLoggedIn, location.pathname, navigate]);

  const onLoginSuccess = async (accessToken) => {
    console.log("Login successful: Access token", accessToken);

    try {
      if (!accessToken) {
        setLoginError(false);
        return;
      }

      console.log("Sending token to server...");

      const response = await fetch('https://mayiliragu.in/api/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (response.ok) {
        console.log("Server response received.");
        const data = await response.json();
        console.log("Server data:", data);

        if (data.credentials) {
          localStorage.setItem('accessToken', data.credentials);
          setIsLoggedIn(true);
          setLoginError(true);
          setShowIntroduction(true);
          navigate('/introduction');
        }
      } else {
        console.log("Server error:", response.statusText);
        const errorMessage = await response.text();
        setLoginError(false);
      }
    } catch (error) {
      console.error("Login failed:", error);
      setLoginError(false);
    }
  };

  const onLoginFailure = (error) => {
    console.error("Login Failed:", error);
    setLoginError(false);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setShowIntroduction(false);
    navigate('/');
    localStorage.removeItem('accessToken');
  };

  const hideIntroduction = () => {
    setShowIntroduction(false);
  };

  return (
    <div className="App">
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} />
      ) : (
        <>
          <Header isLoggedIn={isLoggedIn} />

          <div className="main-content"> 
            {isLoggedIn ? (
              <Dashboard hideIntroduction={hideIntroduction} />
            ) : (
              <>
                <Body />
                <div className="google-login-container">
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      console.log("Credential response:", credentialResponse);
                      const accessToken = credentialResponse?.credential;
                      onLoginSuccess(accessToken);
                    }}
                    onError={(error) => {
                      console.log("Login Failed:", error);
                      onLoginFailure(error);
                    }}
                    className="google-login-btn"
                  />
                </div>
              </>
            )}

            {isLoggedIn && <button className="sign-out-btn" onClick={logout}>Sign out</button>}
            {!loginError && <div className="invalid-login">Unauthorized access. Please contact support.</div>}
            <Outlet />
          </div>

          <Footer />
          {showIntroduction && !isLoggedIn && <Introduction />}
        </>
      )}
    </div>
  );
}

export default App;
