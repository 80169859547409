
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Dashboard.css';
import panIcon from './picture/panicon.png';
import aadhaarIcon from './picture/aadhaaricon.png';
import dropdown from './picture/dropdown.png';
import pricejewel from './priceimage/pricejewel.png';
import homeicon from './picture/homeicon.png';

const Dashboard = ({ hideIntroduction }) => {
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState(null);
  const [isPANDropdownOpen, setPANDropdownOpen] = useState(false);
  const [isAadhaarDropdownOpen, setAadhaarDropdownOpen] = useState(false);
  const [panDropdownRotation, setPanDropdownRotation] = useState(0);
  const [aadhaarDropdownRotation, setAadhaarDropdownRotation] = useState(0);
  const [isPriceUpdateButtonEnabled, setPriceUpdateButtonEnabled] = useState(false);
  const [isHomeButtonEnabled, setHomeButtonEnabled] = useState(false);

  useEffect(() => {
    if (location.pathname === "/introduction") {
      setHomeButtonEnabled(true);
    } else {
      setHomeButtonEnabled(false);
    }
  }, [location.pathname]);
  

  useEffect(() => {
    if (location.pathname === "/introduction") {
      setSelectedLink(null);
    }
  }, [location.pathname]);
  useEffect(() => {
    if (location.pathname === "/pan360" || location.pathname === "/aadhaar360") {
      setSelectedLink(location.pathname);
    } else {
      setSelectedLink(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/priceupdates") {
      setPriceUpdateButtonEnabled(true);
    } else {
      setPriceUpdateButtonEnabled(false);
    }
  }, [location.pathname]);

  const togglePANDropdown = () => {
    setPANDropdownOpen(!isPANDropdownOpen);
    setPanDropdownRotation(isPANDropdownOpen ? 0 : 180); 
  };

  const toggleAadhaarDropdown = () => {
    setAadhaarDropdownOpen(!isAadhaarDropdownOpen);
    setAadhaarDropdownRotation(isAadhaarDropdownOpen ? 0 : 180); 
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  const handleLinkClick = (link) => {
    setSelectedLink(link);
    hideIntroduction(); 
  };

  return (
    <div className="dashboard">
      <ul>
      <Link to="/introduction" className={`hometext-style ${isHomeButtonEnabled ? 'enabled' : 'disabled'}`}>
  <div id="homeborder">
    <div className="hometext">
      <img src={homeicon} alt="Home Icon" className="homeicon" />
      <p>Home</p>
    </div>
  </div>
</Link>


        <li onClick={togglePANDropdown}>
          <div className="dashboard-icon-container">
            <img src={panIcon} alt="PAN Icon" className="dashboard-icon" />
          </div>
          <span>PAN</span>
          <img
            src={dropdown}
            alt="drop Icon"
            className="dropdown-icon"
            style={{ transform: `rotate(${panDropdownRotation}deg)` }}
          />
          {isPANDropdownOpen && (
            <ul className="dropdown" onClick={handleDropdownClick}>
              <div id="pan360">
                <li>
                  <Link
                    to="/pan360"
                    style={{
                      backgroundColor: selectedLink === "/pan360" ? "rgba(255, 217, 102, 0.5)" : "",
                      width: "120px",
                      borderRadius: "8px",
                      height: "40px",
                      position: "absolute",
                      display: "block",
                      top: "100%",
                      transform: "translate(0%, -50%)",
                      left: "50%",
                      textAlign: "center",
                      lineHeight: "40px",
                      marginTop:"6px",
                      fontWeight: selectedLink === "/pan360" ? "bold" : "",
                    }}
                    onClick={() => handleLinkClick("/pan360")}
                  >
                    PAN 360
                  </Link>
                </li>
              </div>
            </ul>
          )}
        </li>

        <li onClick={toggleAadhaarDropdown}>
          <div className="dashboard-icon-container">
            <img src={aadhaarIcon} alt="Aadhaar Icon" className="dashboard-icon" />
          </div>
          <span>Aadhaar</span>
          <img
            src={dropdown}
            alt="drop Icon"
            className="dropdown-iconaadh"
            style={{ transform: `rotate(${aadhaarDropdownRotation}deg)` }}
          />
          {isAadhaarDropdownOpen && (
            <ul className="basic-validation-dropdown" onClick={handleDropdownClick}>
              <div id="aadhaar360">
                <li>
                  <Link
                    to="/aadhaar360"
                    style={{ backgroundColor: selectedLink === "/aadhaar360" ? "rgba(255, 217, 102, 0.5)" : "",
                    width: "120px",
                    borderRadius: "8px",
                    height: "40px",
                    position: "absolute",
                    display: "block",
                    top: "100%",
                    transform: "translate(0%, -50%)",
                    left: "50%",
                    textAlign: "center",
                    lineHeight: "40px", 
                    marginTop:"6px",
                    fontWeight: selectedLink === "/aadhaar360" ? "bold" : "",
                  }}
                    onClick={() => handleLinkClick("/aadhaar360")}
                  >
                    Aadhaar 360
                  </Link>
                </li>
              </div>
            </ul>
          )}
        </li>
        <Link to="/priceupdates" className={`priceupdate-style ${isPriceUpdateButtonEnabled ? 'enabled' : 'disabled'}`}>
  <div id="priceupdatebox">
    <div className="priceupdatetext">
      <img src={pricejewel} alt="Price Icon" className="pricejewel-icon" />
      <div id="priceup">Price Updates</div>
    </div>
  </div>
</Link>

      </ul>
    </div>
  );
};

export default Dashboard;